'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import NProgress from 'nprogress';
import { Suspense, useEffect } from 'react';

function NProgressDone() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    NProgress.done();
  }, [pathname, searchParams]);
  return null;
}

export default function NextNProgress() {
  return (
    <Suspense fallback={null}>
      <NProgressDone />
    </Suspense>
  );
}
