export const EXTERNAL_URL = `https://www.${process.env.PROD_HOST}`;
// Map our locales to the Prepr i18n locales
export const DEFAULT_LOCALE = 'nl';
export const LOCALES = {
  nl: 'nl-NL',
  be: 'nl-BE',
} as const;
export const ITEMS_PER_PAGE = 6;
export const ITEMS_PER_PAGE_PAST_EVENTS = 3;

export const MAX_CONTAINER_WIDTH = 1416;

export const SKIP_LINK_ID = 'main-content';
export const SHOW_ALL_SOLUTION_ID = 'show-all';
export const GENERAL_SOLUTION_ID = 'general';

export const ROUTES = {
  NewsOverviewPage: {
    en: 'news',
    nl: 'nieuws',
    fr: 'nouvelles',
    be: 'nieuws',
  },
  CasesOverviewPage: {
    en: 'cases',
    nl: 'cases',
    fr: 'cas',
    be: 'cases',
  },
  SolutionsOverviewPage: {
    en: 'solutions',
    nl: 'oplossingen',
    fr: 'solutions',
    be: 'oplossingen',
  },
  EventsOverviewPage: {
    en: 'events',
    nl: 'evenementen',
    fr: 'événements',
    be: 'evenementen',
  },
  CompaniesOverviewPage: {
    en: 'companies',
    nl: 'bedrijven',
    fr: 'entreprises',
    be: 'bedrijven',
  },
  VacanciesOverviewPage: {
    en: 'vacancies',
    nl: 'vacatures',
    fr: 'offres-demploi',
    be: 'vacatures',
  },
  VacancyApplyPage: {
    en: 'apply',
    nl: 'solliciteer',
    fr: 'postuler',
    be: 'solliciteer',
  },
  ThankYouPage: {
    en: 'thank-you',
    nl: 'bedankt',
    fr: 'merci',
    be: 'bedankt',
  },
  CookieDeclarationPage: {
    en: 'privacy-cookie-policy',
    nl: 'privacy-cookie-policy',
    fr: 'privacy-cookie-policy',
    be: 'privacy-cookie-policy',
  },
  BrandPage: {
    en: 'our-story',
    nl: 'ons-verhaal',
    fr: 'notre-histoire',
    be: 'ons-verhaal',
  },
} as const;

export const DEFAULT_FOCUS_POINT = 'CENTER_CENTER';
export const FOCUS_POINT = {
  CENTER_CENTER: 'center center',
  CENTER_TOP: 'center top',
  RIGHT_TOP: 'right top',
  RIGHT_CENTER: 'right center',
  RIGHT_BOTTOM: 'right bottom',
  CENTER_BOTTOM: 'center bottom',
  LEFT_BOTTOM: 'left bottom',
  LEFT_CENTER: 'left center',
  LEFT_TOP: 'left top',
} as const;

export const FOCUSABLE_ELS_SELECTOR =
  'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])';

export const DETAIL_PAGES = [
  'ContentPage',
  'EventPage',
  'LocationPage',
  'NewsPage',
  'RoutePage',
];

export const DAYS = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY',
] as const;

export const BG_COLOR_GREEN = 'GREEN';
export const BG_COLOR_LIME = 'LIME';
export const BG_COLOR_PURPLE = 'PURPLE';

export const BRAND_STORIES_ID = 'brand-stories';
