import { DEFAULT_LOCALE } from '@constants/constants';
import { Footer } from '@type-declarations/footer';
import { Locale } from '@type-declarations/locale';
import { MainMenu } from '@type-declarations/mainMenu';
import { PreprLocale, PreprPage } from '@type-declarations/prepr';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface Store {
  preview?: boolean;
  locale: Locale;
  config: {
    footer: Footer | null;
    mainMenu: MainMenu | null;
  };
  page?: {
    id: string;
    typename: PreprPage;
    localizations?: {
      id: string;
      typename: PreprPage;
      locale: PreprLocale;
      slug: string;
    }[];
  };
}

type Props = { store: Store } & PropsWithChildren;

const StoreContext = createContext<
  Props & { setStore: Dispatch<SetStateAction<Store>> }
>({
  store: {
    preview: false,
    locale: DEFAULT_LOCALE,
    config: {
      footer: null,
      mainMenu: null,
    },
  },
  setStore: () => {},
});

// Export the provider as we need to wrap the entire app with it
export function StoreProvider({ store: storeProps, children }: Props) {
  const [store, setStore] = useState(storeProps);

  useEffect(() => {
    setStore(storeProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeProps.preview, storeProps.page]);

  // Make the provider update only when it should.
  // We only want to force re-renders if the store state changes.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly!
  // we want to keep things very performant.
  const memoedValue = useMemo(() => ({ store, setStore }), [store, setStore]);

  return (
    <StoreContext.Provider value={memoedValue}>
      {children}
    </StoreContext.Provider>
  );
}

// Let's only export the `useStore` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useStore() {
  return useContext(StoreContext);
}
