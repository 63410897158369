import Icon from '@atoms/Icon/Icon';
import { ChangeEvent, HTMLAttributes } from 'react';

import styles from './SelectInput.module.scss';

interface Props extends HTMLAttributes<HTMLSelectElement> {
  id: string;
  name?: string;
  label: string;
  value?: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: {
    value: string;
    label: string;
  }[];
  placeholder?: string;
}

export default function SelectInput({
  id,
  name,
  label,
  value,
  options,
  onChange,
  placeholder,
  ...attributes
}: Props) {
  return (
    <div className={styles.wrapper}>
      <select
        aria-label={label}
        className={styles.select}
        onChange={onChange}
        defaultValue={value}
        id={id}
        name={name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...attributes}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <Icon icon="arrow-down" className={styles.icon} />
    </div>
  );
}
