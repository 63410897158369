import Icon, { IconType } from '@atoms/Icon/Icon';
import Link from '@atoms/Link/Link';
import { ButtonSize, ButtonVariants } from '@type-declarations/cta';
import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef } from 'react';

import styles from './Button.module.scss';
import WrapInButton from './WrapInButton';

/**
 * Button inner
 */
interface ButtonInnerProps {
  hideLabel: boolean;
  iconLeft?: IconType;
  icon?: IconType | false | null;
  isRawLabel: boolean;
  label: string;
}

function ButtonInner({
  hideLabel,
  iconLeft,
  icon,
  isRawLabel,
  label,
}: ButtonInnerProps) {
  const labelClass = clsx(styles.label, hideLabel && 'u-visually-hidden');

  return (
    <span className={styles.inner}>
      {iconLeft && (
        <span
          className={clsx(styles.iconContainer, styles.iconContainerLeft)}
          aria-hidden="true"
        >
          <Icon className={styles.svgIcon} icon={iconLeft} />
        </span>
      )}
      {isRawLabel ? (
        <span
          className={labelClass}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: label }}
        />
      ) : (
        <span className={labelClass}>{label}</span>
      )}
      {icon && (
        <span
          className={clsx(styles.iconContainer, styles.iconContainerRight)}
          aria-hidden="true"
        >
          <Icon className={styles.svgIcon} icon={icon} />
        </span>
      )}
    </span>
  );
}

/**
 * Button
 */
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  className?: string;
  loading?: boolean;
  modifier?: 'redIcon' | 'filterActive' | null;
  href?: string;
  icon?: IconType | null;
  iconLeft?: IconType;
  isRawLabel?: boolean;
  stretch?: boolean;
  submit?: boolean;
  hideLabel?: boolean;
  noAnimation?: boolean;
  variant?: ButtonVariants;
  onClick?: () => void;
  rounded?: boolean;
  prefetch?: boolean;
  target?: '_blank' | '_self';
  type?: 'button' | 'submit';
  form?: string;
  size?: ButtonSize;
}

const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      className: extraClasses,
      modifier,
      href,
      icon,
      iconLeft,
      loading = false,
      isRawLabel = false,
      stretch = false,
      submit = false,
      hideLabel = false,
      variant = 'primary',
      noAnimation = false,
      rounded = false,
      size,
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    const className = clsx(
      styles.button,
      styles[variant],
      extraClasses,
      rounded && styles.rounded,
      hideLabel && styles.hideLabel,
      noAnimation && styles.noAnimation,
      loading && clsx(styles.loading, styles.noAnimation),
      stretch && styles.stretch,
      modifier && styles[modifier],
      size && styles[size],
      icon && styles.hasIcon,
      iconLeft && styles.hasIconLeft,
    );
    const title = hideLabel ? label : undefined;
    const childButtonAttrs = {
      hideLabel,
      iconLeft,
      icon: loading ? 'loader' : icon,
      isRawLabel,
      label,
      loading,
    };

    if (href) {
      return (
        <Link
          // @ts-expect-error TODO: Ref can be of type HTMLAnchorElement of HTMLButtonElement
          ref={ref}
          href={href}
          className={className}
          title={title}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ButtonInner {...childButtonAttrs} />
        </Link>
      );
    }
    return (
      <WrapInButton
        // @ts-expect-error TODO: Ref can be of type HTMLAnchorElement of HTMLButtonElement
        ref={ref}
        className={className}
        title={title}
        submit={submit}
        rest={rest}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <ButtonInner {...childButtonAttrs} />
      </WrapInButton>
    );
  },
);

Button.displayName = 'Button';

export default Button;
