import { SKIP_LINK_ID } from '@constants/constants';
import useTranslations from '@hooks/useTranslations';

export default function Skiplink() {
  const t = useTranslations();

  return (
    <a
      href={`#${SKIP_LINK_ID}`}
      className="u-visually-hidden u-visually-hidden--focusable"
    >
      {t.skiplink}
    </a>
  );
}
