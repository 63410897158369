import { ROUTES } from '@constants/constants';
import { InternalLink, Locale } from '@type-declarations/prepr';

interface internalRouteProps {
  internalLink: InternalLink;
  locale: Locale;
}

const setRoute = ({ internalLink, locale }: internalRouteProps): string => {
  const { slug, typename, parent } = internalLink.page[0];

  let route = '/'; // returns homepage on default (if no matches)
  if (typename === 'ContentPage' && parent?.length) {
    // Call the function again with the parent page to get the full route
    return `${setRoute({ internalLink: { page: [parent[0]] }, locale })}/${slug}`;
  }

  switch (typename) {
    case 'NewsOverviewPage':
    case 'CasesOverviewPage':
    case 'CompaniesOverviewPage':
    case 'EventsOverviewPage':
    case 'SolutionsOverviewPage':
    case 'VacanciesOverviewPage':
    case 'BrandPage':
      route = ROUTES[typename][locale];
      break;
    case 'NewsPage':
      route = `${ROUTES.NewsOverviewPage[locale]}/${slug}`;
      break;
    case 'CasePage':
      route = `${ROUTES.CasesOverviewPage[locale]}/${slug}`;
      break;
    case 'CompanyPage':
      route = `${ROUTES.CompaniesOverviewPage[locale]}/${slug}`;
      break;
    case 'SolutionPage':
      route = `${ROUTES.SolutionsOverviewPage[locale]}/${slug}`;
      break;
    case 'EventPage':
      route = `${ROUTES.EventsOverviewPage[locale]}/${slug}`;
      break;
    case 'VacancyPage':
      route = `${ROUTES.VacanciesOverviewPage[locale]}/${slug}`;
      break;
    case 'ThankYouPage':
      route = ROUTES.ThankYouPage[locale];
      break;
    case 'HomePage':
      route = `/`;
      break;
    case 'ContentPage': {
      route = slug!;
      break;
    }

    default:
      console.warn('Page type not recognized', { typename, slug, locale });
  }

  return route;
};

export default function getInternalRoute({
  internalLink,
  locale,
}: internalRouteProps): string {
  return setRoute({
    internalLink,
    locale,
  });
}
