import NavigationItem from '@atoms/NavigationItem/NavigationItem';
import { NavigationItem as NavigationItemProps } from '@type-declarations/navigationItem';
import clsx from 'clsx';

import styles from './Navbar.module.scss';

interface Props {
  menu: NavigationItemProps[];
  currentPageId?: string;
}

export default function Navbar({ menu, currentPageId }: Props) {
  return (
    <ul className={clsx(styles.navbar, 'u-list-clean')}>
      {menu.map(menuItem => {
        const linkPageID = menuItem.internalLink?.page[0].id;
        const isActive = linkPageID === currentPageId;

        return (
          <li className={styles.listItem} key={menuItem.id}>
            <NavigationItem
              item={menuItem}
              className={clsx(styles.navbarItem, isActive && styles.active)}
            />
          </li>
        );
      })}
    </ul>
  );
}
