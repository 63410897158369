import { forwardRef } from 'react';

import styles from './MenuToggle.module.scss';

interface Props {
  menuIsShown: boolean;
  onClick: () => void;
  menuID: string;
}

const MenuToggle = forwardRef<HTMLButtonElement, Props>(
  ({ menuIsShown, onClick, menuID }, ref) => (
    <button
      className={styles.menuToggle}
      type="button"
      onClick={onClick}
      aria-label={menuIsShown ? 'Sluit menu' : 'Open menu'}
      aria-expanded={menuIsShown}
      aria-haspopup
      aria-controls={menuID}
      ref={ref}
    >
      <span className="u-visually-hidden">Menu</span>
      <div data-js="burger-menu" className={styles.icon}>
        <span />
        <span />
        <span />
      </div>
    </button>
  ),
);

MenuToggle.displayName = 'MenuToggle';

export default MenuToggle;
