import { forwardRef, PropsWithChildren } from 'react';

/**
 * Wrap in Button
 */
interface Props extends PropsWithChildren {
  className: string;
  title?: string;
  submit: boolean;
  rest?: { [key: string]: unknown };
}

const WrapInButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, title, submit, rest, children }: Props, ref) => (
    <button
      ref={ref}
      className={className}
      type={submit ? 'submit' : 'button'}
      title={title}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </button>
  ),
);

WrapInButton.displayName = 'WrapInButton';

export default WrapInButton;
