import { LOCALES } from '@constants/constants';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import SelectInput from '@molecules/Inputs/SelectInput';
import { Locale } from '@type-declarations/prepr';
import { setCookie } from '@utils/clientCookies';
import getInternalRoute from '@utils/getInternalRoute';
import { useRouter } from 'next/router';
import { ChangeEvent } from 'react';

const options = [
  {
    label: 'Nederlands (nl)',
    value: 'nl',
  },
  {
    label: 'Nederlands (be)',
    value: 'be',
  },
];

export default function LanguageSwitch() {
  const t = useTranslations();

  const {
    store: { page, locale },
    setStore,
  } = useStore();

  const { push } = useRouter();

  const handleSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value as Locale;
    const localization = page?.localizations?.find(
      loc => loc.locale === LOCALES[value],
    );

    /* Redirect to homepage if no localization for selected language is available */
    const route = localization
      ? getInternalRoute({
          internalLink: { page: [localization] },
          locale: value,
        })
      : '/';

    setCookie('NEXT_LOCALE', value);
    setStore(store => ({ ...store, locale: value }));
    push(route, undefined, { locale: value });
  };

  return (
    <SelectInput
      id="language-switch"
      value={locale}
      options={options}
      onChange={handleSelect}
      label={t.selectLanguage}
    />
  );
}
