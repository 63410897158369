import NextNProgress from '@atoms/NProgress/NProgress';
import Seo from '@atoms/Seo/Seo';
import Skiplink from '@atoms/Skiplink/Skiplink';
import { SKIP_LINK_ID } from '@constants/constants';
import Footer from '@organisms/Footer/Footer';
import Header from '@organisms/Header/Header';
import { Image } from '@type-declarations/media';
import { SeoProps } from '@type-declarations/seo';
import { PropsWithChildren } from 'react';

interface Props {
  title?: string;
  seo?: SeoProps;
  image?: Image;
  suffix?: string;
  children: React.ReactNode;
}

function BaseTemplate({
  title,
  suffix = 'Anchr',
  seo,
  image,
  children,
}: PropsWithChildren<Props>) {
  return (
    <>
      <Seo title={title} suffix={suffix} seo={seo} image={image} />
      <Skiplink />
      <Header />
      <main id={SKIP_LINK_ID}>{children}</main>
      <Footer />
      <NextNProgress />
    </>
  );
}

export default BaseTemplate;
