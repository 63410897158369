import AlertTriangle from '@svg/alert-triangle.svg';
import ArrowDown from '@svg/arrow-down.svg';
import ArrowLeft from '@svg/arrow-left.svg';
import ArrowRight from '@svg/arrow-right.svg';
import ArrowUp from '@svg/arrow-up.svg';
import Briefcase from '@svg/briefcase.svg';
import Calendar from '@svg/calendar.svg';
import Check from '@svg/check.svg';
import ChevronDown from '@svg/chevron-down.svg';
import ChevronLeft from '@svg/chevron-left.svg';
import ChevronRight from '@svg/chevron-right.svg';
import ChevronUp from '@svg/chevron-up.svg';
import CreditCard from '@svg/credit-card.svg';
import Edit from '@svg/edit-2.svg';
import Facebook from '@svg/facebook.svg';
import File from '@svg/file.svg';
import Filter from '@svg/filter.svg';
import Instagram from '@svg/instagram.svg';
import Linkedin from '@svg/linkedin.svg';
import Loader from '@svg/loader.svg';
import MapPin from '@svg/map-pin.svg';
import Play from '@svg/play.svg';
import Plus from '@svg/plus.svg';
import Remove from '@svg/remove.svg';
import Search from '@svg/search.svg';
import Share from '@svg/share-2.svg';
import Upload from '@svg/upload.svg';
import Cross from '@svg/x.svg';

// Keep in alphabetical order :)
const icons = {
  'alert-triangle': AlertTriangle,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  briefcase: Briefcase,
  calendar: Calendar,
  check: Check,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'credit-card': CreditCard,
  cross: Cross,
  edit: Edit,
  facebook: Facebook,
  file: File,
  filter: Filter,
  instagram: Instagram,
  linkedin: Linkedin,
  loader: Loader,
  'map-pin': MapPin,
  play: Play,
  plus: Plus,
  search: Search,
  share: Share,
  upload: Upload,
  remove: Remove,
};

export type IconType = keyof typeof icons;

export interface Props {
  icon: IconType;
  className?: string;
  strokeWidth?: number;
}

export default function Icon({ icon, className, strokeWidth = 1.25 }: Props) {
  const IconElement = icons[icon];

  if (!IconElement) return null;

  return <IconElement strokeWidth={strokeWidth} className={className} />;
}
