import Link from '@atoms/Link/Link';
import useLocale from '@hooks/useLocale';
import { NavigationItem as NavigationItemType } from '@type-declarations/navigationItem';
import getInternalRoute from '@utils/getInternalRoute';

interface Props {
  item: NavigationItemType;
  className?: string;
}

function NavigationItem({ item, className }: Props) {
  const { internalLink, externalLink, title } = item;
  const locale = useLocale();
  let href = externalLink;

  if (internalLink?.page && internalLink.page[0]) {
    href = getInternalRoute({
      internalLink,
      locale,
    });
  }

  if (!href) return null;

  return (
    <Link href={href} className={className}>
      {title}
    </Link>
  );
}

export default NavigationItem;
