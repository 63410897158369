import { Image } from '@type-declarations/media';
import { SeoProps } from '@type-declarations/seo';
import Head from 'next/head';

interface Props {
  title?: string;
  description?: string;
  image?: Image;
  suffix?: string;
  seo?: SeoProps;
}

function Seo({ title, image, description, suffix, seo }: Props) {
  const pageTitle =
    seo?.metaTitle || [title, suffix].filter(part => !!part).join(' - ');

  return (
    <Head>
      {/* HTML Meta Tags */}
      <title>{pageTitle}</title>

      {process.env.ENV !== 'prod' && (
        <meta name="robots" content="noindex, nofollow" />
      )}

      {seo?.ogType && <meta property="og:type" content="website" />}

      {(seo?.metaDescription ||
        seo?.metaTitle ||
        seo?.ogDescription ||
        seo?.ogTitle ||
        seo?.ogImage) && (
        <meta name="twitter:card" content="summary_large_image" />
      )}

      {(seo?.metaDescription || seo?.ogDescription || description) && (
        <>
          <meta
            name="description"
            content={seo?.metaDescription || seo?.ogDescription || description}
          />
          <meta
            property="og:description"
            content={seo?.ogDescription || seo?.metaDescription || description}
          />
          <meta
            name="twitter:description"
            content={seo?.ogDescription || seo?.metaDescription || description}
          />
        </>
      )}

      <meta
        property="og:title"
        content={seo?.ogTitle || seo?.metaTitle || pageTitle}
      />
      <meta
        name="twitter:title"
        content={seo?.ogTitle || seo?.metaTitle || pageTitle}
      />

      {(seo?.ogImage?.url || image) && (
        <>
          <meta property="og:image" content={seo?.ogImage?.url || image?.url} />
          <meta
            name="twitter:image"
            content={seo?.ogImage?.url || image?.url}
          />
        </>
      )}
    </Head>
  );
}

export default Seo;
