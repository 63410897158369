import getInternalUrl from '@utils/getInternalUrl';
import setExternalUrl from '@utils/setExternalUrl';
import { shouldTriggerStartEvent } from '@utils/shouldTriggerStartEvent';
import NextLink from 'next/link';
import nProgress from 'nprogress';
import { forwardRef, PropsWithChildren } from 'react';

/**
 * Link component that handles internal and external links.
 */
interface Props extends PropsWithChildren {
  href: string;
  prefetch?: boolean;
  onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  [key: string]: unknown;
}

const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ href, children, onClick, prefetch, ...rest }: Props, ref) => {
    const internalUrl = getInternalUrl(href);

    if (prefetch === false) {
      return (
        <a
          ref={ref}
          href={internalUrl || setExternalUrl(href)}
          itemProp="url"
          onClick={onClick}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        >
          {children}
        </a>
      );
    }

    if (internalUrl) {
      return (
        <NextLink
          ref={ref}
          href={internalUrl}
          itemProp="url"
          onClick={event => {
            if (shouldTriggerStartEvent(href, event)) {
              nProgress.set(0.3);
              nProgress.start();
            }
            if (onClick) onClick(event);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        >
          {children}
        </NextLink>
      );
    }

    return (
      <a
        ref={ref}
        href={setExternalUrl(href)}
        target="_blank"
        rel="noopener"
        itemProp="url"
        onClick={onClick}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {children}
      </a>
    );
  },
);

Link.displayName = 'Link';

export default Link;
